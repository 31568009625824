import React from 'react'

import { DailyWebOrderStatisticsPage } from '../../components'

class WebshopReports extends React.Component {
  render() {
    return <DailyWebOrderStatisticsPage />
  }
}

export default WebshopReports
